import React, { useMemo } from "react"
import PageRoot from "../components/PageRoot"
import { Trans, useTranslation } from "react-i18next"
import "../style/landing.less"
import { StaticImage } from "gatsby-plugin-image"
import VideoOverlay from "../components/VideoOverlay"
import {
  Button,
  Col,
  Collapse,
  Layout as AntLayout,
  Row,
  Space,
  Tag,
} from "antd"
import {
  CheckCircleFilled,
  FieldTimeOutlined,
  FileProtectOutlined,
  MinusOutlined,
  PlaySquareOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { useAuth0 } from "@auth0/auth0-react"
import useBreakpoint from "../hooks/useBreakpoint"
import GenericHeader from "../components/GenericHeader"

const { Footer } = AntLayout
const { Panel } = Collapse

const rootLayoutRowSettings = {
  justify: "space-around",
}

const rootLayoutColSettings = {
  xs: 22,
  sm: 22,
  md: 22,
  lg: 20,
  xl: 20,
  xxl: 16,
}

const halfWidthColSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 12,
  xl: 12,
  xxl: 12,
}

const halfWidthRtlColSettings = {
  first: {
    xs: { span: 24, order: 1 },
    sm: { span: 24, order: 1 },
    md: { span: 24, order: 1 },
    lg: { span: 12, order: 2 },
    xl: { span: 12, order: 2 },
    xxl: { span: 12, order: 2 },
  },
  second: {
    xs: { span: 24, order: 2 },
    sm: { span: 24, order: 2 },
    md: { span: 24, order: 2 },
    lg: { span: 12, order: 1 },
    xl: { span: 12, order: 1 },
    xxl: { span: 12, order: 1 },
  },
}

const thirdWidthColSettings = {
  xs: 24,
  sm: 24,
  md: 24,
  lg: 8,
  xl: 8,
  xxl: 8,
}

const rowGutter = [64, 64]

const cardVideoContainerStyle = {
  xs: {
    position: "absolute",
    width: 308,
    height: 160,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  sm: {
    position: "absolute",
    width: 308,
    height: 160,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  md: {
    position: "absolute",
    width: 600,
    height: 400,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  lg: {
    position: "absolute",
    width: 400,
    height: 400,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  xl: {
    position: "absolute",
    width: 550,
    height: 400,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
  xxl: {
    position: "absolute",
    width: 700,
    height: 400,
    top: 0,
    bottom: 0,
    margin: "auto",
    left: 0,
    right: 0,
  },
}

const cardVideoStyle = {
  xs: {
    position: "absolute",
    top: 6,
    left: 33,
    width: 240,
  },
  sm: {
    position: "absolute",
    top: 6,
    left: 33,
    width: 240,
  },
  md: {
    position: "absolute",
    top: 8,
    left: 66,
    width: 468,
  },
  lg: {
    position: "absolute",
    top: 12,
    left: 50,
    width: 300,
  },
  xl: {
    position: "absolute",
    top: 8,
    left: 59,
    width: 432,
  },
  xxl: {
    position: "absolute",
    top: 18,
    left: 90,
    width: 518,
  },
}

const introContainerVideoStyle = {
  xs: {
    position: "absolute",
    height: 200,
    width: 350,
    left: 0,
    right: 0,
    margin: "auto",
  },
  sm: {
    position: "absolute",
    height: 200,
    width: 350,
    left: 0,
    right: 0,
    margin: "auto",
  },
  md: {
    position: "absolute",
    height: 350,
    width: 500,
    left: 0,
    right: 0,
    margin: "auto",
  },
  lg: {
    position: "absolute",
    height: 500,
    width: 800,
    left: 0,
    right: 0,
    margin: "auto",
  },
  xl: {
    position: "absolute",
    height: 800,
    width: 1200,
    left: 0,
    right: 0,
    margin: "auto",
  },
  xxl: {
    position: "absolute",
    height: 800,
    width: 1200,
    left: 0,
    right: 0,
    margin: "auto",
  },
}

const introVideoStyle = {
  xs: {
    position: "absolute",
    top: 10,
    left: 95,
    width: 200,
  },
  sm: {
    position: "absolute",
    top: 10,
    left: 95,
    width: 200,
  },
  md: {
    position: "absolute",
    top: 23,
    left: 193,
    width: 399,
  },
  lg: {
    position: "absolute",
    top: 38,
    left: 332,
    width: 684,
  },
  xl: {
    position: "absolute",
    top: 38,
    left: 332,
    width: 684,
  },
  xxl: {
    position: "absolute",
    top: 38,
    left: 332,
    width: 684,
  },
}

const headerVideoContainerStyle = {
  xs: {
    display: "none",
  },
  sm: {
    display: "none",
  },
  md: {
    display: "none",
  },
  lg: {
    position: "absolute",
    bottom: 260,
    right: 0,
    width: 550,
    height: 300,
    zIndex: 0,
  },
  xl: {
    position: "absolute",
    top: 240,
    right: 0,
    width: 760,
    height: 400,
    zIndex: 0,
  },
  xxl: {
    position: "absolute",
    top: 160,
    right: 0,
    width: 1100,
    height: 400,
    zIndex: 0,
  },
}

const headerVideoOverlayStyle = {
  xs: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
  sm: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
  md: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
  lg: {
    position: "absolute",
    top: 31,
    left: 140,
    width: 460,
  },
  xl: {
    position: "absolute",
    top: 43,
    left: 196,
    width: 627,
  },
  xxl: {
    position: "absolute",
    top: 61,
    left: 283,
    width: 912,
  },
}

const authorBackgroundImageStyle = {
  xs: {
    width: "700%",
    marginLeft: "-350%",
  },
  sm: {
    width: "236%",
    marginLeft: "-35%",
  },
  md: {
    width: "300%",
    marginLeft: "-150%",
  },
  lg: {
    width: "100%",
    marginLeft: "0",
  },
  xl: {
    width: "100%",
    marginLeft: "0",
  },
  xxl: {
    width: "100%",
    marginLeft: 0,
  },
}

const recapColSettings = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 6,
}

const getResponsiveStyle = (styles, breakpoint) => {
  return styles[breakpoint] || styles[Object.keys(styles)[0]]
}

const LandingPage = () => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()
  const breakpoint = useBreakpoint()

  const handleSignUp = async () => {
    await loginWithRedirect({
      screen_hint: "signUp",
      ui_language: (process.env.GATSBY_LANG || "it_IT").split("_")[0],
    })
  }

  const tags_row1 = t("landing:appRecap.tags_row1").split(",")

  const contentRecap = useMemo(
    () => (
      <section className="bg-main-color content-recap-row">
        <Row {...rootLayoutRowSettings}>
          <Col {...rootLayoutColSettings}>
            <Row justify="space-between">
              <Col {...recapColSettings}>
                <Space>
                  <PlaySquareOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalVideos"
                    components={[<b />]}
                  />
                </Space>
              </Col>
              <Col {...recapColSettings}>
                <Space>
                  <FileProtectOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalCourse"
                    components={[<b />]}
                  />
                </Space>
              </Col>
              <Col {...recapColSettings}>
                <Space>
                  <UserOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalTeachers"
                    components={[<b />]}
                  />
                </Space>
              </Col>
              <Col {...recapColSettings}>
                <Space>
                  <FieldTimeOutlined />
                  <Trans
                    i18nKey="landing:allContentRecap.totalTime"
                    components={[<b />]}
                  />
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    ),
    []
  )

  return (
    <PageRoot title={t("label:landingPage")} layout="landing">
      <div className="landing-page">
        <section className="bg-mesh header-video-overlay">
          <GenericHeader
            data={{
              title: t("landing:header.title"),
              subtitle: t("landing:header.content"),
              home: true,
              actions: [
                <Button
                  type="primary"
                  className="bg-main-color"
                  onClick={handleSignUp}
                >
                  {t("button:signUpNow")}
                </Button>,
              ],
            }}
          />
          <VideoOverlay
            placeholder={
              <StaticImage src={"/assets/images/hexagon-bg.jpg"} alt="mac" />
            }
            source={"/assets/images/landing/presentazionedesktop.mp4"}
          />
        </section>
        {contentRecap}
        <section className="pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Row gutter={rowGutter}>
                <Col {...thirdWidthColSettings}>
                  <h2>
                    <Trans
                      i18nKey="landing:mainFeatures.first.title"
                      components={[<br />]}
                    />
                  </h2>
                  <p>{t("landing:mainFeatures.first.content")}</p>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <h2>
                    <Trans
                      i18nKey="landing:mainFeatures.second.title"
                      components={[<br />]}
                    />
                  </h2>
                  <p>{t("landing:mainFeatures.second.content")}</p>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <h2>
                    <Trans
                      i18nKey="landing:mainFeatures.third.title"
                      components={[<br />]}
                    />
                  </h2>
                  <p>{t("landing:mainFeatures.third.content")}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section>
          <div
            style={{
              ...getResponsiveStyle(authorBackgroundImageStyle, breakpoint),
            }}
            className="width-200 ml-0"
          >
            <StaticImage
              style={{ width: "100%" }}
              src="../../static/assets/images/landing/speakers-carousel.png"
              alt="Authors"
            />
          </div>

          <div
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              left: "0",
              bottom: "0",
              height: 150,
              margin: "auto",
            }}
            className="top--50 height-100"
          >
            <Row align="center">
              <Col {...halfWidthColSettings}>
                <h1 className="text-center">
                  <Trans i18nKey="landing:authors" components={[<span />]} />
                </h1>
              </Col>
            </Row>
          </div>
        </section>
        <section className="pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Row gutter={rowGutter}>
                <Col {...thirdWidthColSettings}>
                  <h2>{t("landing:secondaryFeatures.first.title")}</h2>
                  <p>{t("landing:secondaryFeatures.first.content")}</p>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <h2>{t("landing:secondaryFeatures.second.title")}</h2>
                  <p>{t("landing:secondaryFeatures.second.content")}</p>
                </Col>
                <Col {...thirdWidthColSettings}>
                  <h2>{t("landing:secondaryFeatures.third.title")}</h2>
                  <p>{t("landing:secondaryFeatures.third.content")}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section className="bg-mesh pb">
          <div
            style={{
              position: "relative",
              height: getResponsiveStyle(introContainerVideoStyle, breakpoint)
                .height,
            }}
          >
            <div
              style={{
                ...getResponsiveStyle(introContainerVideoStyle, breakpoint),
              }}
            >
              <StaticImage
                src="../../static/assets/images/landing/tablet_laptop.png"
                alt="mac"
              />
            </div>
          </div>
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <h2 className="text-center">
                <Trans
                  i18nKey="landing:appRecap.title"
                  components={[<span className="highlighted" />]}
                />
              </h2>
              <Row gutter={[16, 16]} justify="center" wrap>
                {tags_row1.map((tag, index) => (
                  <Col key={index}>
                    <Tag key={index}>{tag}</Tag>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </section>
        <section className="pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Space direction="vertical" size={34}>
                <div className="bg-gray landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthRtlColSettings.first}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            left: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/laptop04.png"
                            alt="mac"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthRtlColSettings.second}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.course.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.course.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature3")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.course.feature4")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthColSettings}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            left: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/laptop01.png"
                            alt="mac"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthColSettings}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.human3D.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.human3D.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature3")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.human3D.feature4")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="bg-gray landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthRtlColSettings.first}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            left: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/laptop02.png"
                            alt="categories"
                          />
                          <div
                            style={{
                              ...getResponsiveStyle(cardVideoStyle, breakpoint),
                              left: 0,
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthRtlColSettings.second}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.category.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.category.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.category.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.category.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.category.feature3")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
                <div className="landing-card">
                  <Row gutter={rowGutter}>
                    <Col {...halfWidthColSettings}>
                      <div
                        style={{
                          position: "relative",
                          height: getResponsiveStyle(
                            cardVideoContainerStyle,
                            breakpoint
                          ).height,
                        }}
                      >
                        <div
                          style={{
                            ...getResponsiveStyle(
                              cardVideoContainerStyle,
                              breakpoint
                            ),
                            right: 0,
                          }}
                        >
                          <StaticImage
                            src="../../static/assets/images/landing/laptop03.png"
                            alt="mac"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col {...halfWidthColSettings}>
                      <h2>
                        <Trans
                          i18nKey="landing:cards.webinar.title"
                          components={[<span className="highlighted" />]}
                        />
                      </h2>
                      <p>{t("landing:cards.webinar.content")}</p>
                      <ul>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature1")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature2")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature3")}
                        </li>
                        <li>
                          <CheckCircleFilled />
                          {t("landing:cards.webinar.feature4")}
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </Space>
            </Col>
          </Row>
        </section>
        <section className="bg-mesh pt pb">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <Row>
                <Col {...halfWidthRtlColSettings.second}>
                  <Space>
                    {/*  <StaticImage
                     src="../../static/assets/images/landing/appstore.svg"
                     alt="mobile app image"
                     width={50}
                     />
                     <StaticImage
                     src="../../static/assets/images/landing/playstore.svg"
                     alt="mobile app image"
                     width={50}
                     />*/}
                  </Space>
                  <h2>
                    <Trans
                      i18nKey="landing:mobileApp.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:mobileApp.content")}</p>
                  <Button
                    type="primary"
                    className="bg-main-color"
                    onClick={handleSignUp}
                  >
                    {t("button:signUpNow")}
                  </Button>
                </Col>
                <Col {...halfWidthRtlColSettings.first}>
                  <div style={{ marginTop: -230 }} className="single-app-img">
                    <StaticImage
                      src="../../static/assets/images/landing/coppia-telefoni.png"
                      alt="mobile app image"
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section>
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <h2 className="text-center mb-100">
                <Trans
                  i18nKey="landing:extraContent.title"
                  components={[<span className="highlighted" />]}
                />
              </h2>
              <Row gutter={rowGutter}>
                <Col {...thirdWidthColSettings} className="border-r-white">
                  <img
                    className="extra-content-icon mb-20"
                    src="/assets/images/landing/icon-on-demand.svg"
                    alt="icon on-demand"
                  />
                  <h2 className="mb-20">
                    <Trans
                      i18nKey="landing:extraContent.content1.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:extraContent.content1.content")}</p>
                  <StaticImage
                    src="../../static/assets/images/landing/tablet01.png"
                    alt="mobile app image"
                  />
                </Col>
                <Col {...thirdWidthColSettings} className="border-r-white">
                  <img
                    className="extra-content-icon mb-20"
                    src="/assets/images/landing/icon-certification.svg"
                    alt="icon certification"
                  />
                  <h2 className="mb-20">
                    <Trans
                      i18nKey="landing:extraContent.content2.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p>{t("landing:extraContent.content2.content")}</p>
                  <StaticImage
                    src="../../static/assets/images/landing/tablet02.png"
                    alt="mobile app image"
                  />
                </Col>
                <Col {...thirdWidthColSettings}>
                  <img
                    className="extra-content-icon mb-20"
                    src="/assets/images/landing/icon-multilang.svg"
                    alt="icon multilang"
                  />
                  <h2 className="mb-20">
                    <Trans
                      i18nKey="landing:extraContent.content3.title"
                      components={[<span className="highlighted" />]}
                    />
                  </h2>
                  <p className="mb-36">
                    {t("landing:extraContent.content3.content")}
                  </p>
                  <StaticImage
                    src="../../static/assets/images/landing/tablet03.png"
                    alt="mobile app image"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </section>
        <section className="pt">
          <Row {...rootLayoutRowSettings}>
            <Col xs={24} sm={24} md={24} lg={12} xl={10} xxl={10}>
              <h2 className="text-center">{t("landing:faq.title")}</h2>
              <Collapse
                defaultActiveKey={[]}
                expandIconPosition="right"
                className="faq-collapse"
                expandIcon={({ isActive }) =>
                  isActive ? <MinusOutlined /> : <PlusOutlined />
                }
              >
                <Panel header={t("landing:faq.faq1.question")} key="faq1">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq1.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq2.question")} key="faq2">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq2.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
                <Panel header={t("landing:faq.faq3.question")} key="faq3">
                  <p>
                    <Trans
                      i18nKey="landing:faq.faq3.answer"
                      components={[<br />]}
                    />
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </section>
        <section className="bg-mesh gradient pt">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <div className="text-center">
                <Space direction="vertical" size={48}>
                  <StaticImage
                    src="../../static/assets/images/landing/devices01.png"
                    alt="SportScience"
                  />
                </Space>
              </div>
            </Col>
          </Row>
        </section>
        {contentRecap}

        <section>
          <div className="landing-card">
            <Row gutter={rowGutter}>
              <Col {...halfWidthColSettings} className="border-r-white">
                <Row gutter={rowGutter}>
                  <Col {...halfWidthColSettings}></Col>
                  <Col {...halfWidthColSettings}>
                    <ul>
                      <li>
                        <CheckCircleFilled />
                        {t("landing:footer.feature1")}
                      </li>
                      <li>
                        <CheckCircleFilled />
                        {t("landing:footer.feature2")}
                      </li>
                      <li>
                        <CheckCircleFilled />
                        {t("landing:footer.feature3")}
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
              <Col {...halfWidthColSettings}>
                <Row gutter={rowGutter}>
                  <Col {...halfWidthColSettings}>
                    <ul>
                      <li>
                        <CheckCircleFilled />
                        {t("landing:footer.feature4")}
                      </li>
                      <li>
                        <CheckCircleFilled />
                        {t("landing:footer.feature5")}
                      </li>
                      <li>
                        <CheckCircleFilled />
                        {t("landing:footer.feature6")}
                      </li>
                    </ul>
                  </Col>
                  <Col {...halfWidthColSettings}></Col>
                </Row>
              </Col>
              <Col {...halfWidthColSettings}></Col>
            </Row>
          </div>
        </section>
        <section className="pt-none-tmp">
          <Row {...rootLayoutRowSettings}>
            <Col {...rootLayoutColSettings}>
              <div className="text-center">
                <h2 className="text-center">
                  <Trans
                    i18nKey="landing:footer.title"
                    components={[<span className="highlighted" />]}
                  />
                </h2>
                <Button
                  type="primary"
                  className="bg-main-color footer-btn"
                  onClick={handleSignUp}
                >
                  {t("button:signUpNow")}
                </Button>
              </div>
            </Col>
          </Row>
        </section>

        <section>
          <Footer className={"main-footer"}>
            <Row className="footer-wrapper">
              <Col span={24} className="each-col-bottom">
                <h5>{t("message:footerDescription2")}</h5>
                <h5>{t("message:footerDescription3")}</h5>
              </Col>
            </Row>
          </Footer>
        </section>
      </div>
    </PageRoot>
  )
}

export default LandingPage
